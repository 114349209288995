@import './varialble.scss';

@mixin commonButton {
  background-color: $primary-background-color;
  border: none;
  border-radius: 4px;
  color: $white-bg-1;
  font-size: 14px;
  font-weight: 500;
}

@mixin borderedButton {
  background-color: $white-bg-1;
  border: 1px solid $primary-background-color;
  border-radius: 4px;
  color: $primary-background-color;
  font-size: 14px;
  font-weight: 500;
}

@mixin commonTable {
  th {
    font-size: 16px;
    white-space: nowrap;
    font-weight: 700;
    background-color: $white-bg-1;
    padding: 10px 10px;
    border: none;
  }
  th:before {
    content: none !important;
  }
  td {
    padding: 10px 10px;
    font-size: 16px;
    font-weight: 400;
    border: none;
    white-space: nowrap;
  }
  tbody {
    tr {
      &:nth-child(odd) {
        background-color: $table-cell-color;
      }
      &:nth-child(even) {
        background-color: $white-bg-1;
      }
    }
  }
}

@mixin commonProgressMeter {
  height: 27px;
  max-width: 100%;
  // min-width: 13%;
  margin-left: 2px;
  span {
    padding-left: 5px;
  }
  transition: all 1.5s linear;
  .inner-count {
    transition: all 1.5s linear;
    span {
      padding-left: 5px;
    }
  }
}

@mixin commonHyperLinkRow {
  td:not(:last-child) {
    .ant-typography,
    span {
      width: fit-content;
      margin: 0;
      color: $hyper-link-color;
      cursor: pointer;
      border-bottom: 1px solid $hyper-link-color;
    }
  }
}

@mixin projectDetailHeading {
  font-size: 14px;
  font-weight: 400;
  color: $gray-color;
}

@mixin indeterminateCheckbox {
  height: 2px;
  background-color: $white-bg-1;
  left: 50% !important;
  transform: translate(-50%, -50%) scale(1) !important;
}

@mixin commentPopoverContent {
  .assess-comment {
    color: $gray-color;
    font-size: 12px;
  }
  .assess-comment-view-more {
    color: $gray-color;
    float: right;
    width: fit-content;
    font-size: 12px;
    cursor: pointer;
  }
}
